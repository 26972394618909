import { REWARD_OFFERING} from "../../constants";
import { getRedeemUrlWithContext } from "../../utils";
import OnboardingPage from "../../common/components/OnboardingPage";

function StarCarWashTacticalMain() {
  const redeemUrl = getRedeemUrlWithContext("star-car-wash-tactical");

  return (
    <>
        <OnboardingPage
          redeemUrl={redeemUrl}
          rewardId={REWARD_OFFERING.starcarwashtactical}
        />
    </>
  );
}

export default StarCarWashTacticalMain;
