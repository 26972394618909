/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import parse from "html-react-parser";
import JsBarcode from "jsbarcode";
import { useEffect, useState } from "react";
import { REWARD_OFFERING } from "../../constants";
import { fromPublic, replaceGlobalConfigVars } from "../../utils";
import { Divider } from "@mui/material";
import Dropdown from "../../common/components/Dropdown";
import DropdownContent from "../../common/components/DropdownContent";
import OnboardingContent from "../../common/components/OnboardingContent";

const StyledRedeemBarcodeCard = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0; 
  width: 100%;
  flex-direction: column;
  display: flex;
  background-color: #EEF3F2;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  margin: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden; 
  max-width: 100vw; 
`;

const GreenBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 75vh;
  background-color: #14a248;
  z-index: 0;
`;

const HelpArticleContainer = styled.div`
  position: relative;
  justify-content: center;
  flex-direction: column;
  padding: 18px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #EEF3F2;
  margin-top: -30px;
  min-height: 50vh;
  padding-bottom: max(env(safe-area-inset-bottom), 20px);
  flex: 1;
`;

const BottomContainerContent = styled.div`
  padding: 0 25px;
  margin-top: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  position: relative;
  margin-bottom: 10px;
  max-width: 100vw; 
  
  img {
    max-width: 125%;
    height: auto;
    transform: translateX(0);
  }
`;

const PartnershipText = styled.p`
  font-size: 10px !important;
  font-weight: 400 !important;
  line-height: 13px !important;
`;

const BarcodeContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 18px;

  img {
    height: 100px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 18px;
`;

const WhiteCard = styled.div`
  display: flex;
  z-index: 9999; 
  position: relative;
  background-color: #ffffff;
  justify-content: center;
  flex-direction: column;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 8px;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const BarcodeValue = styled.div`
  font-family: RobotoMono;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.16em;
  color: #2f383c;
`;

const WhiteCardTopContent = styled.div`
  color: #2f383c;
  padding: 12px;
  background-color: #f5f5f5;
  p {
    font-weight: 400;
    font-size: 12px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`;

const WhiteCardBottomContent = styled.div`
  color: #2f383c;
  padding: 12px;
  background-color: #f5f5f5;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  p {
    font-weight: 400;
    font-size: 12px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`;

const WhiteCardContentHeader = styled.div`
  display: flex;
  align-items: center;

  img {
    padding-left: 8px;
    filter: brightness(0); 
  }
`;

const HelpHeading = styled.div`
  text-align: left;
  width: 100%;
`;


function RedeemBarcodeCard({ hintOnClick, offsetHeight, barcode }: any) {
  const [barcodeFormatted, setBarcodeFormatted] = useState("");

  useEffect(() => {
    if (barcode) {
      JsBarcode("#barcode", barcode, {
        displayValue: false,
        width: 6,
      });

      let barcodeFormatted = "";
      barcode.split("").forEach((v: string, i: number) => {
        if (i % 4 === 0) {
          barcodeFormatted += " ";
        }
        barcodeFormatted += v;
      });
      setBarcodeFormatted(barcodeFormatted);
    }
  }, [barcode]);

  const shellCfg = GLOBAL_CFG[REWARD_OFFERING.SHELL];

  
  return (
    <StyledRedeemBarcodeCard
      css={css`
        min-height: calc(100vh - ${offsetHeight});
      `}
    >
      <GreenBackground />
      <div style={{ position: 'relative', zIndex: 2 }}>
        <BottomContainerContent>
          <img src={fromPublic(shellCfg.redeem?.barcodeCard?.saveAmountLogo || "")}/>
        </BottomContainerContent>
        <WhiteCard>
          <WhiteCardTopContent>
            <WhiteCardContentHeader>
              {parse(shellCfg.redeem.barcodeCard.topTitle)}
              <img src={fromPublic(shellCfg.redeem.barcodeCard.hintCardTriggerIcon)} onClick={hintOnClick} />
            </WhiteCardContentHeader>
            <div>
              {parse(replaceGlobalConfigVars(shellCfg.redeem.barcodeCard.topContent))}
            </div>
          </WhiteCardTopContent>
          <BarcodeContainer>
            <img id="barcode" />
            <BarcodeValue>{barcodeFormatted}</BarcodeValue>
          </BarcodeContainer>
          <WhiteCardBottomContent>
            <WhiteCardContentHeader>
              {parse(shellCfg.redeem?.barcodeCard?.bottomTitle || "")}
            </WhiteCardContentHeader>
            {parse(replaceGlobalConfigVars(shellCfg.redeem.barcodeCard.bottomContent))}
          </WhiteCardBottomContent>
        </WhiteCard>
        <HelpArticleContainer>
          <OnboardingContent>
            {parse(replaceGlobalConfigVars(shellCfg.onboarding1.content))}
            <Divider sx={{marginTop: '16px', marginBottom: '16px' }} />
            <HelpHeading>
              {parse(
                replaceGlobalConfigVars(shellCfg.onboarding1.dropdownHeading ?? "")
              )}
            </HelpHeading>
            {(shellCfg.onboarding1.dropdowns ?? []).map((dropdown, index) => (
              <Dropdown
                key={index}
                items={[
                  parse(replaceGlobalConfigVars(dropdown.heading)),
                  <DropdownContent
                    content={dropdown.content}
                    iconUrl={shellCfg.onboarding1.pointIcon}
                  />,
                ]}
              />
              ))}
            <LogoContainer>
              <PartnershipText>In partnership with</PartnershipText>
              <img src={fromPublic(shellCfg.redeem?.logo)} width={100} height={30} />
            </LogoContainer>
          </OnboardingContent>
        </HelpArticleContainer>
      </div>
    </StyledRedeemBarcodeCard>
  );
}

export default RedeemBarcodeCard;
