import { REWARD_OFFERING } from "../../constants";
import { getRedeemUrlWithContext } from "../../utils";
import OnboardingPage from "../../common/components/OnboardingPage";

function TourDeBrisbaneMain() {
  const redeemUrl = getRedeemUrlWithContext("tour-de-brisbane");

  return (
    <>
        <OnboardingPage
          partnerCfg={GLOBAL_CFG["tour-de-brisbane"]}
          redeemUrl={redeemUrl}
          coupon={"RIDETDB2025"}
          rewardId={REWARD_OFFERING.tourdebrisbane}
        />
    </>
  );
}

export default TourDeBrisbaneMain;