import React, { useEffect } from "react";
import "swiper/css";
import {
  getPlatformFormattedText,
  redirect,
  redirectLoaded,
  replaceGlobalConfigVars,
  sendAnalytics,
  wireCallbacks,
} from "../../utils";
import OnboardingStep from "./OnboardingStep";
import parse from "html-react-parser";
import OnboardingContent from "./OnboardingContent";
import { CLIENT, FLOW, GLOBAL_CONST, REWARD_OFFERING } from "../../constants";
import { useRecoilValue } from "recoil";
import { appParamState } from "../../atoms";
import StickyCTA from "./OnboardingStickyCTA";
import semver from "semver";
import Dropdown from "./Dropdown";
import CouponBox from "./CouponBox";
import DropdownContent from "./DropdownContent";
import { Box, Divider } from "@mui/material";
import styled from "@emotion/styled";

const HelpHeading = styled.div`
  text-align: left;
  width: 100%;
`;

type OnboardingPageParams = {
  redeemUrl: string;
  rewardId: REWARD_OFFERING;
  partnerCfg?: { onboarding1: Onboarding; coupon: Coupon; vars: ConfigVars } | undefined;
  coupon?: string | null;
  viewType?: "external" | "tab";
};

const OnboardingPage: React.FC<OnboardingPageParams> = ({
  redeemUrl,
  rewardId,
  partnerCfg,
  coupon,
  viewType = "tab", 
}) => {
  const { flow } = useRecoilValue(appParamState);
  const redeemUrlEncoded = encodeURIComponent(redeemUrl);
  const globalPartnerCfg = GLOBAL_CFG[rewardId];

  useEffect(() => {
    wireCallbacks();
  }, []);

  useEffect(() => {
    if (flow === FLOW.ONBOARDING || flow === FLOW.REDEEM) {
      redirectLoaded();
      sendAnalytics("onboarding1ScreenOnLoad");
      redirect(`${GLOBAL_CONST.CLIENT_ID}://rewards/onboarding-complete`);
    }
  }, [flow]);

  const isLegacy = () => {
    const isLinktLegacy = semver.lte(GLOBAL_CONST.APP_VERSION, "4.0.0");
    const isLinktgoLegacy = semver.lte(GLOBAL_CONST.APP_VERSION, "3.0.1");
    return (
      (GLOBAL_CONST.CLIENT_ID === CLIENT.LINKT && isLinktLegacy) ||
      (GLOBAL_CONST.CLIENT_ID === CLIENT.LINKTGO && isLinktgoLegacy)
    );
  };

  const toggleLoading = async () => {
    await sendAnalytics("onboarding1AcceptButton");
    const url = `${GLOBAL_CONST.CLIENT_ID}://rewards/link?url=${redeemUrlEncoded}`;
    await redirect(isLegacy() ? url : `${url}&view-type=${viewType}`);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box sx={{ flex: 1, padding: '24px', paddingBottom: '200px' }}>
        <OnboardingStep
          image={globalPartnerCfg.onboarding1.image}
          imageLottie={globalPartnerCfg.onboarding1.lottie}
        >
          <OnboardingContent>
            {parse(replaceGlobalConfigVars(globalPartnerCfg.onboarding1.content ?? ''))}
            {partnerCfg && coupon && (
              <CouponBox partnerCfg={partnerCfg} coupon={coupon ?? ''} />
            )}
            <Divider sx={{marginTop: '16px', marginBottom: '16px' }} />
            <HelpHeading>
              {parse(replaceGlobalConfigVars(globalPartnerCfg.onboarding1.dropdownHeading ?? ''))}
            </HelpHeading>
            {(globalPartnerCfg.onboarding1.dropdowns ?? []).map((dropdown, index) => (
              <Dropdown
                key={index}
                items={[
                  parse(replaceGlobalConfigVars(dropdown.heading ?? '')),
                  <DropdownContent
                    content={dropdown.content}
                    iconUrl={globalPartnerCfg.onboarding1.pointIcon}
                  />
                ]}
              />
            ))}
          </OnboardingContent>
        </OnboardingStep>
      </Box>
        <StickyCTA
          legalContent={parse(replaceGlobalConfigVars(globalPartnerCfg.onboarding1.legal ?? ''))}
          ctaText={getPlatformFormattedText(globalPartnerCfg.onboarding1.ctaText ?? "Redeem")}
          ctaAction={toggleLoading}
        />
    </Box>
  );
};

export default OnboardingPage;