/** @jsxImportSource @emotion/react */

import React, { useState, useEffect, useRef } from "react";
import OnboardingCTA from "./OnboardingCTA";
import RewardsButton from "./RewardsButton";
import styled from "@emotion/styled";

const OnboardingStickyContainer = styled.div<{ dynamicPadding: number }>`
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 180px;
  padding-bottom: ${(props) => props.dynamicPadding}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: white;
  z-index: 9999;
`;

const OnboardingStickyContent = styled.div`
  width: 90%;
  align-self: center;
  margin: 4px auto 0 auto;
  font-size: 14px !important;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  gap: 8px; 
`;

const LegalContent = styled.div`
  width: 95% !important;
`;

interface StickyCTAProps {
  legalContent: React.ReactNode;
  ctaText: string;
  ctaAction: () => void;
}

const StickyCTA: React.FC<StickyCTAProps> = ({ legalContent, ctaText, ctaAction }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [padding, setPadding] = useState(0);

  useEffect(() => {
    const updatePadding = () => {
      if (containerRef.current) {
        const height = containerRef.current.scrollHeight;
        if (height > 200) {
          setPadding(height - 200);
        } else {
          setPadding(0);
        }
      }
    };

    updatePadding();
    window.addEventListener("resize", updatePadding);
    return () => window.removeEventListener("resize", updatePadding);
  }, []);

  return (
    <OnboardingStickyContainer ref={containerRef} dynamicPadding={padding}>
      <OnboardingStickyContent>
        <LegalContent>{legalContent}</LegalContent>
        <OnboardingCTA>
          <RewardsButton clickHandler={ctaAction}>{ctaText}</RewardsButton>
        </OnboardingCTA>
      </OnboardingStickyContent>
    </OnboardingStickyContainer>
  );
};

export default StickyCTA;